<template>
  <div class="flex justify-center">
    <div class="text-justify fmu-container font-sans py-10 font-thin">
      <p>
        <strong>BESTELLBEDINGUNGEN</strong><br><br>
        Laufzeit eines Basic Marketing Abos sind immer 12 Monate für jedes Abo; es können mehrere Abos mit
        unterschiedlichen Laufzeiten abgeschlossen werden. Beginn eines Abos ist immer am ersten des Folgemonats, in dem
        das Abo abgeschlossen wurde; der Zeitraum zwischen Bestellung des Abos und Abobeginn ist gratis.
        Kündigung/Verlängerung: Ein Abo verlängert sich immer um ein Jahr, wenn nicht spätestens 3 Monate vor
        Laufzeitende gekündigt wird. <br>
        Zahlung: <br><br>
        a) Zahlart Kreditkarte oder SEPA Lastschrift: Die Abbuchung erfolgt bei monatlicher oder jährlicher Zahlung
        immer im Voraus am 1. des Monats, in dem das Abo beginnt. <br>
        b) Zahlart Unitex Zentralregulierung: Die Zahlungsabwicklung erfolgt gemäß der mit der Unitex geschlossenen
        Vereinbarungen.
        Rechnungsstellung: Die Ausstellung der Rechnung erfolgt immer am 5. für den vorherigen Monat oder bei jährlicher
        Zahlung am 5. des zweiten Abomonats für jeweils ein Jahr. <br><br>
        Hutter & Unger behält sich ein ständiges einseitiges außerordentliches Kündigungsrecht mit einer Frist von einem
        Monat vor, wenn die Anzahl der Abonnenten die Kosten nicht deckt. Eine Weitergabe des Contents an Dritte (z.B.
        andere Händler, Firmen, Personen…) ist ausdrücklich nicht gestattet und wird bei Missbrauch mit einer
        Vertragsstrafe von 5.000 € je Fall geahndet. Die Abo Nutzung gilt immer nur für eine Person. Die Abo Konditionen
        gelten immer nur für die Nutzung für eine Firma mit bis zu 10 Filialen und Firmen mit bis zu 10 Mio. € Brutto
        Umsatz im Jahr. Jeder Content kann je Kanal bis zu 25.000 Stück Auflage (Print) bzw. bei Onlinekanälen bis zu
        25.000 Impressionen genutzt werden. Wichtige Info: Bei Printvorlagen haftet Hutter & Unger nicht für postalische
        Vorgaben, eine Prüfung auf postalische Richtigkeit obliegt dem Händler. <br><br>
        Alle Preise zzgl. gesetzl. Mwst. Es gelten ausschließlich die AGB der Hutter & Unger GmbH (Stand 1. Februar
        2006). Du findest diese im Folgenden.
      </p>
      <br>
      <strong>ALLGEMEINE VERTRAGSBEDINGUNGEN DER <br>
        HUTTER & UNGER GMBH WERBEAGENTUR</strong> <br><br>

      (Stand: 1. Februar 2006) <br><br>

      <strong>1. Geltung</strong> <br><br>

      1.1 Die Annahme und die Ausführung von Werbeaufträgen erfolgt ausschließlich aufgrund nachfolgender Allgemeiner
      Geschäftsbedingungen.
      <br><br>

      1.2 Mit der Auftragserteilung durch den Kunden werden gleichzeitig diese Allgemeinen Vertragsbedingungen
      Vertragsbestandteil. Entgegenstehende oder von diesen Allgemeinen Vertragsbedingungen abweichende Bedingungen des
      Kunden haben keine Geltung. <br><br>

      1.3 Unsere AGB gelten nur gegenüber Unternehmern (§ 14 BGB), juristischen Personen des öffentlichen Rechts oder
      einem öffentlichrechtlichen Sondervermögen im Sinne von § 310 Abs. 1 Satz 1 BGB. <br><br>

      1.4 Unsere Allgemeinen Vertragsbedingungen gelten auch für alle künftigen Geschäfte mit dem Kunden. <br><br>

      <strong>2. Angebote</strong> <br><br>

      2.1 Die von uns abgegebenen Angebote sind für den zwischen den Vertragsparteien vereinbarten Zeitraum verbindlich.
      Änderungs- oder Sonderwünsche des Kunden können die Preiskalkulation verändern.<br><br>

      2.2 Für den Fall der nachträglichen Änderung der nach Angebotsabgabe und Vertragsschluss maßgeblichen Verhältnisse
      hat der Kunde Preissteigerungen bei Zulieferern oder Mehrkosten, die durch Sonderwünsche
      des Kunden bzw. durch Terminwünsche des Kunden entstehen, sowie Mehrkosten, die durch einen aufgrund gesteigerter
      Qualitätsansprüche des Kunden notwendig gewordenen Wechsel von einem Zulieferer/Hersteller
      zu einem anderen Zulieferer/Hersteller verursacht werden, zusätzlich zu vergüten, sofern die Preiserhöhung nicht
      außer Verhältnis zum Maß der eingetretenen Veränderung steht. Dies gilt nicht, wenn die
      Änderung der maßgeblichen Verhältnisse für uns bereits bei Vertragsschluss sicher und konkret vorhersehbar
      waren.<br><br>

      <strong>3. Pflichten des Kunden und Rechte Dritter</strong><br><br>

      3.1 Für die rechtzeitige und vollständige Überlassung von Texten. Bildern, Logos, Gestaltungsmustern und sonstigen
      Inhalten ist ausschließlich der Kunde verantwortlich.<br><br>

      3.2 Wir sind nicht verpflichtet, die Texte und sonstigen Inhalte (z.B. Logos, Marken) auf eine mögliche Verletzung
      von Rechten Dritter zu prüfen. Der Kunde hat alle zur Durchführung der Leistung
      erforderlichen Rechte (z.B. zur Nutzung von Namen, Logos von Markenartikeln) zu beschaffen.<br><br>

      3.3 Das Risiko der rechtlichen Zulässigkeit einer Werbung wird vom Kunden getragen. Dies gilt insbesondere für den
      Fall, dass Werbemaßnahmen gegen Vorschriften des Markenrechts, Wettbewerbsrechts,
      des Urheberrechts und der speziellen Werberechtsgesetze verstoßen. Wir sind jedoch verpflichtet, auf rechtliche
      Risiken hinzuweisen, sofern uns diese bei der Vorbereitung der Werbung bekannt werden.
      Erhalten wir Kenntnis von unzulässigen Inhalten oder etwaigen Rechtsverletzungen, sind wir berechtigt, jedoch
      nicht verpflichtet, nach unserem Ermessen die Werbung ganz oder teilweise ohne vorhergehende
      Benachrichtigung des Kunden zu ändern oder zu beenden. Die hierdurch entstehenden Kosten trägt der Kunde.<br><br>

      3.4 Wir haften nicht für in der Werbung enthaltende Sachaussagen über Produkte und Leistungen des Kunden, soweit
      diese Aussagen auf Vorgaben des Kunden beruhen.<br><br>

      3.5 Wir haften auch nicht für die patent-, urheber- und markenrechtliche Schutz- oder Eintragungsfähigkeit der im
      Rahmen des Vertrages gelieferten Ideen, Anregungen, Vorschläge, Konzeptionen, Entwürfe.<br><br>

      3.6 Der Kunde wird uns von allen Ansprüchen Dritter und etwaiger Aufwendungen (insbesondere Kosten der
      Rechtsverteidigung) freistellen, die wegen der Verletzung von Rechten Dritter bei Durchführung unserer
      Leistungen entstehen. Der Kunde wird uns mit allen Informationen und Unterlagen bei der Rechtsverteidigung
      gegenüber Dritten unterstützen.<br><br>

      3.7 Der Kunde ist verpflichtet, Beanstandungen rechtzeitig und unmissverständlich schriftlich uns gegenüber
      anzumelden, damit die optimale Erstellung eines Kommunikations- und Werbeplans entsprechend der
      vertraglichen Vereinbarungen gewährleistet wird. Der Kunde hat von uns vorgelegte Unterlagen, Entwürfe,
      Werbemittel etc. innerhalb der von uns gesetzten angemessenen Frist auf Mängel zu prüfen und diese
      unverzüglich schriftlich anzuzeigen. Kosten, die durch die verspätete Mitteilung des Kunden entstehen, hat der
      Kunde im Falle seines Verschuldens selbst zu tragen.<br><br>

      3.8 Wir legen die vom Kunden korrigierten und genehmigten Unterlagen, Entwürfe, Werbemittel etc. ihrer weiteren
      Bearbeitung zugrunde. Sollten sich Folgefehler aufgrund der mangelhaften Korrektur durch den
      Kunden ergeben, d. h. das Vorliegen von Fehlern oder Nichtvorhandensein zugesicherten Eigenschaften auf
      mangelhaften Korrekturen durch den Kunden beruhen, bestehen uns gegenüber nur Gewährleistungsansprüche,
      soweit die Fehler bzw. das Nichtvorhandensein zugesicherter Eigenschaften grob fahrlässig oder vorsätzlich von uns
      zu vertreten sind.<br><br>

      3.9 Die Freigabe von Produktion und Veröffentlichung wird von dem Kunden erteilt. Überträgt der Kunde
      ausnahmsweise die Freigabe ganz oder teilweise an uns, stellt uns der Kunde von Ansprüchen Dritter frei.<br><br>

      <strong>4. Urheberrecht- und Nutzungsrechte</strong><br><br>

      4.1 Das Urheberrecht bleibt bei dem, der es geschaffen hat. Der Kunde erhält nur für den konkret erteilten Auftrag
      das einmalige Verwertungs- und Nutzungsrecht an unseren Leistungen. Diese Übertragung des
      Nutzungsrechts ist zeitlich, örtlich, nach Verwendungszweck, nach Auflage und Ausgabe und in jeder sonstigen Weise
      beschränkt auf die im Auftrag bezeichnete Maßnahme und Filiale oder Niederlassung des Kunden.
      Mangels ausdrücklicher Vereinbarung gilt als Zweck des Vertrages nur der vom Kunden bei Auftragserteilung
      erkennbar gemachte Zweck.<br><br>

      4.2 Das Nutzungsrecht geht erst nach vollständiger Zahlung der Vergütung durch den Kunden auf diesen über.<br><br>

      4.3 Wiederholungsnutzungen (z.B. Nachauflage) oder Mehrfachnutzungen (z.B. für ein anderes Produkt oder
      Vertriebsgebiet) oder die Änderung oder Übertragung unserer Leistungen an dritte Unternehmen bedürfen
      unserer vorhergehenden schriftlichen Zustimmung.<br><br>

      4.4 Die Übertragung eingeräumter Benutzungsrechte auf Dritte bedarf der Einwilligung der Agentur.<br><br>

      <strong>5. Lieferung und Lieferzeit</strong><br><br>

      5.1 Bei Druck, Stanzung, Transfer und sogenannten geplotteten Auflagen sind fertigungstechnisch bedingte
      Mengenabweichungen von bis zu 10 % möglich, die vom Kunden entsprechend zu bezahlen sind.<br><br>

      5.2 Wir sind jederzeit zu Teillieferungen und Teilleistungen und zur sofortigen Berechnung des gelieferten Teiles
      oder der erbrachten Leistungen berechtigt.<br><br>

      5.3 Soweit nicht etwas anderes schriftlich vereinbart wurde, erfolgt die Lieferung ab unseren Geschäftsräumen in
      Wertingen. Die Art der Versendung und Verpackung bleibt uns überlassen. Die Verpackungskosten
      sind in unseren Preisen nicht enthalten.<br><br>

      5.4 Die von uns bezeichneten Termine sind unverbindlich, soweit nicht etwas anderes schriftlich vereinbart
      wurde.<br><br>

      <strong>6. Eigentumsvorbehalt</strong><br><br>

      6.1. An unseren Arbeiten werden nur Nutzungsrechte eingeräumt, ein Eigentumsrecht wird nicht übertragen.<br><br>

      6.2. Gelieferte Waren und Werbemittel bleiben bis zur Erfüllung sämtlicher Forderungen aus der Geschäftsverbindung
      in unserem Eigentum und dürfen solange nur mit unserem Einverständnis im Rahmen eines
      ordnungsgemäßen Geschäftsbetriebs weiterveräußert, verpfändet oder sicherungsübereignet werden. Alle Forderungen
      des Kunden aus einer Weiterveräußerung werden bereits jetzt zur Sicherung sämtlicher
      Forderungen aus der Geschäftsbeziehung an uns abgetreten.<br><br>

      <strong>7. Preise, Zahlungsbedingungen, Zahlungsverzug</strong><br><br>

      7.1 Alle Forderungen sind mit Zugang der Rechnung sofort und ohne Abzug zur Zahlung fällig. Wir sind berechtigt,
      angemessene Abschlagszahlungen zu verlangen.<br><br>

      7.2 Mit Ausnahme der verzugsbegründenden Erstmahnung werden für jede weitere Mahnung während des Zahlungsverzuges
      des Kunden EUR 10,00 berechnet.<br><br>

      7.3 Kommt der Kunde in Zahlungsverzug, so sind wir berechtigt, Verzugszinsen in Höhe von 8 % über dem
      Basiszinssatz, mindestens jedoch 10 % p.a. zu fordern.<br><br>

      7.4 Aufrechnungs-, Zurückbehaltungs- und Leistungsverweigerungsrechte stehen dem Kunden nur zu, wenn seine
      Gegenansprüche rechtskräftig festgestellt oder unbestritten sind. Die Mangelansprüche des Kunden
      bleiben hiervon unberührt. In jedem Fall ist der Kunde zur Ausübung eines
      Zurückbehaltungs-/Leistungsverweigerungsrechtes nur insoweit befugt, als sein Gegenanspruch auf demselben
      Vertragsverhältnis beruht.<br><br>

      <strong>8. Gewährleistung</strong><br><br>

      8.1 Offensichtliche Mängel, Falschlieferungen oder Mengenabweichungen sind uns unverzüglich nach Ablieferung
      schriftlich anzuzeigen. Versteckte Mängel sind unverzüglich, spätestens innerhalb von fünf
      Werktagen nach deren Feststellung zu rügen.<br><br>

      8.2 Bei begründeter Beanstandung steht dem Kunden nach unserer Wahl ein Anspruch auf zweimalige kostenfreie
      Nachbesserung oder auf Ersatzlieferung zu. Führt die zweimalige Nachbesserung oder Ersatzlieferung
      innerhalb einer zumutbaren Frist nicht zum Erfolg, stehen dem Kunden die gesetzlichen Rechte zu.<br><br>

      <strong>9. Haftung, Schadensersatz</strong><br><br>

      9.1 Bei der Produktion, Ver- und Bearbeitung von Drucksachen sind Farb-, Material-, Format- und
      Verarbeitungsabweichungen möglich. Bei der Verarbeitung von Folien sind kleine Materialabweichungen wie
      Blasen- und Faltenbildungen möglich. Bei Bearbeitung von Gegenständen kann deren Beschaffenheit beeinträchtigt
      werden, insbesondere können Einschnitte in die Oberflächen (Blech, Kunststoff, Holz, Glas etc.)
      der zu beschriftenden Gegenstände (Fahrzeuge, Tafeln, Schaufenster etc.) verursacht werden. Hierfür haften wir nur
      nach Maßgabe dieser Regelung.<br><br>

      9.2 Wir weisen darauf hin, dass wir keinen Einfluss auf die Gestaltung der Inhalte von Webseiten haben, auf die
      ein etwaiger Link verweist. Wir distanzieren uns ausdrücklich von sämtlichen Inhalten aller
      Webseiten, auf die ein Link verweist.<br><br>

      9.3 Für Aufträge, die im Namen und auf Rechnung des Auftraggebers an Dritte erteilt werden, übernehmen wir
      gegenüber dem Kunden keinerlei Haftung oder Gewährleistung, soweit uns kein Auswahlverschulden
      trifft. In diesen Fällen treten wir lediglich als Vermittler auf.<br><br>

      9.4 Sofern wir selbst Auftraggeber von Subunternehmen sind, treten wir hiermit sämtliche uns zustehenden
      Gewährleistungs-, Schadensersatz- und sonstigen Ansprüchen aus fehlerhafter, verspäteter oder
      Nichtlieferung an den Kunden ab. Der Kunde ist verpflichtet, bevor er uns in Anspruch nimmt, die abgetretenen
      Ansprüche durchzusetzen.<br><br>

      9.5 Jegliche Schadensersatzansprüche des Kunden, die gleichgültig aus welchem Rechtsgrund, unmittelbar oder
      mittelbar in Zusammenhang mit unseren Leistungen oder der Inanspruchnahme sonstiger
      Leistungen entstehen, sind ausgeschlossen. Dieser Haftungsausschluss gilt nicht bei Verletzung einer
      vertragswesentlichen Pflicht (Kardinalpflicht). Unsere Haftung ist in jedem Fall auf den Ersatz des
      vertragstypischen, vorhersehbaren Schadens beschränkt. (Schadens-) Ersatzansprüche des Kunden gegenüber uns, die
      auf Vertragsstrafenansprüche der Abnehmer unseres Kunden zurückgehen, sind für uns in keinem
      Fall vorhersehbar und vertragstypisch in vorstehendem Sinn. In jedem Fall sind wir berechtigt, den Nachweis eines
      geringeren Schadens zu führen.<br><br>

      9.6 Die vorstehenden Haftungsausschlüsse und -beschränkungen gelten nicht für Schäden aus der Verletzung des
      Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen
      Pflichtverletzung durch uns oder eines unserer gesetzlichen Vertreter oder unserer Erfüllungsgehilfen beruhen. Die
      vorstehenden Haftungsausschlüsse und -beschränkungen gelten auch nicht für sonstige
      Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung durch uns oder eines unserer
      gesetzlichen Vertreter oder eines unserer Erfüllungsgehilfen beruhen oder wenn der sonstige
      Schaden durch das Fehlen einer garantierten Beschaffenheit oder wegen arglistigen Verschweigens eines Mangels
      entstanden ist.<br><br>

      <strong>10. Kündigung durch den Kunden</strong><br><br>

      Bei Kündigung des Vertrages durch den Kunden sind wir unbeschadet des Nachweises höherer bis zum Zeitpunkt der
      Kündigung erbrachter Aufwendungen in jedem Fall berechtigt, eine pauschale Abgeltung unserer
      bis zum Zeitpunkt der Kündigung erbrachten Leistungen und Aufwendungen in Höhe von 30 % des Nettoauftragswertes zu
      verlangen, sofern nicht der Kunde nachweist, dass die Kündigung zu keinem oder einem
      wesentlich geringeren Schaden als die Pauschale geführt wird.<br><br>

      <strong>11. Unsere Werbung und unser Logo</strong><br><br>

      11.1 Wir sind berechtigt, unser Logo und/oder unsere Geschäftsbezeichnung in angemessener Größe im unteren, oberen
      oder seitlichen Bereich der von uns gestalteten Produkte anzubringen. Die hierfür
      entstehenden Kosten werden von uns getragen.<br><br>

      11.2 Mit der Übermittlung, insbesondere per Post, Telefon, Telefax, Email, von Werbung jeglicher Art über uns
      erklärt sich der Kunde bis auf seinen ausdrücklichen Widerruf einverstanden.<br><br>

      <strong>12. Datenschutzrechtliche Hinweise</strong><br><br>

      Wir weisen darauf hin, dass wir Daten des Kunden, die den Geschäftsverkehr mit ihm betreffen, im Sinne des
      Bundesdatenschutzgesetzes speichern und verarbeiten, wozu der Kunde sein ausdrückliches
      Einverständnis erklärt.<br><br>

      <strong>13. Anwendbares Recht, Erfüllungsort, Gerichtsstand</strong><br><br>

      13.1 Es gilt ausschließlich das Recht der Bundesrepublik Deutschland, mit der Ausnahme des einheitlichen
      UN-Kaufrechtes (z.B. CISG).<br><br>

      13.2 Erfüllungsort ist Wertingen. Ausschließlicher Gerichtsstand für alle sich aus dem Vertragsverhältnis
      unmittelbar oder mittelbar ergebenden Streitigkeiten ist Dillingen a.d. Donau, sofern der Kunde
      Kaufmann im Sinne des HGB ist. Dies soll unabhängig von der Kaufmannseigenschaft auch dann gelten, wenn der Kunde
      seinen Wohnsitz oder gewöhnlichen Aufenthalt ins Ausland verlegt oder sein Wohnsitz oder
      gewöhnlicher Aufenthaltsort im Zeitpunkt der Klageerhebung nicht bekannt ist. Wir sind auch berechtigt, am
      allgemeinen Gerichtsstand den Kunden zu klagen.<br><br>

      <strong>Name und Sitz</strong> <br><br>

      Hutter & Unger GmbH<br>
      Werbeagentur<br>
      Gewerbestraße 2 c<br>
      86637 Wertingen<br>
      DEUTSCHLAND<br><br>

      <strong>Handelsregister</strong> <br><br>

      Amtsgericht Augsburg<br>
      HR B 18292<br>
      Geschäftsführer: Christoph Hutter und Andreas Unger<br><br>

      <a href="https://hutter-unger.de" target="_blank" class="underline text-hu-primary">www.hutter-unger.de</a> <br>
      <a href="mailto:info@hutter-unger.de" class="underline text-hu-primary">info@hutter-unger.de</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Conditions',
  components: {},
}
</script>

<style scoped>
</style>
